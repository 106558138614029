import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app/app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './__helpers/authGuard/authGuard';
import { NavbarComponent } from './_sharedMVC/navbar/navbar.component';

import {TranslateModule} from '@ngx-translate/core';
import {CanDeactivateGuard} from './__helpers/can-deactivate-guard.service';
import {ModuleHelper} from './__helpers/entities/ModuleHelper';
import {SharedThingsModule} from './sharedThings.module';
import {Constants} from './__helpers/entities/Constants';
import {ConfirmationService, MessageService} from 'primeng/api';
import {VersionService} from './services/versionCheck.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './__helpers/jwt.interceptor';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {JwtPermissions} from './__helpers/jwtPermissions.service';
import { PublisherService } from './_publisherMVC/publisher.service';
import { TargetingService } from './services/targeting.service';
import { GlobalErrorHandler } from './__helpers/GlobalErrorHandler';
import {RemoveAzurePipe} from './_sharedMVC/_helpers/removeAzure.pipe';
import {GroupSidebarModule} from './_cerberusMVC/groupSidebar.module';
import {SharedPrivateThingsModule} from './_sharedMVC/sharedPrivateThings.module';
import {DataProvidersModule} from './_dataProvidersMVC/_myModule/dataProviders.module';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RemoveAzurePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedThingsModule,
    ConfirmDialogModule,
    TranslateModule.forRoot(ModuleHelper.TRANSLATE_CONFIG),
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: Constants.LINK_HOME,
        pathMatch: 'full',
        // canActivate: [AuthGuard]
      },
      {
        path: '',
        // loadChildren: () => import('./_publicMVC/public.module').then(mod => mod.PublicModule)
        loadChildren: () => import('./_publicMVC/public.module').then(m => m.PublicModule)
      },
      {
        path: Constants.LINK_MANAGER_PART,
        // loadChildren: () => import('./_privateMVC/private.module').then(mod => mod.PrivateModule),
        loadChildren: () => import('./_privateMVC/private.module').then(m => m.PrivateModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_SEGMENTS_PART,
        loadChildren: () => import('./_segmentsMVC/_myModule/segments.module').then(m => m.SegmentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_HOME,
        // loadChildren: () => import('./_homeMVC/home.module').then(mod => mod.HomeModule),
        loadChildren: () => import('./_homeMVC/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_CAMPAIGN_PART,
        // loadChildren: () => import('./_campaignMVC/campaign.module').then(mod => mod.CampaignModule),
        loadChildren: () => import('./_campaignMVC/campaign.module').then(m => m.CampaignModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_DATA_PROVIDER_PART,
        loadChildren: () => import('./_dataProvidersMVC/_myModule/dataProviders.module').then(m => m.DataProvidersModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_ADMIN_PART,
        // loadChildren: () => import('./_adminMVC/admin.module').then(mod => mod.AdminModule),
        loadChildren: () => import('./_adminMVC/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_INVITES_PART,
        // loadChildren: () => import('./_adminMVC/admin.module').then(mod => mod.AdminModule),
        loadChildren: () => import('./_adminMVC/inviteManagement/inviteManagement.module').then(m => m.InviteManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_USER_MANAGEMENT_ADMIN_PART,
        loadChildren: () => import('./_adminMVC/publisherManagement/publisherManagement.module').then(m => m.PublisherManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_APPROVAL_PART,
        // loadChildren: () => import('./_approvalMVC/approval.module').then(mod => mod.ApprovalModule),
        loadChildren: () => import('./_approvalMVC/approval.module').then(m => m.ApprovalModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_BILLING_PART,
        // loadChildren: () => import('./_billingMVC/billing.module').then(mod => mod.BillingModule),
        loadChildren: () => import('./_billingMVC/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_BILLING_ADMIN_PART,
        // loadChildren: () => import('./_billingMVC/billing.module').then(mod => mod.BillingModule),
        loadChildren: () => import('./_billingMVC/billingAdmin/billingAdmin.module').then(m => m.BillingAdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_DEAL_PART,
        // loadChildren: () => import('./_dealsMVC/deals.module').then(mod => mod.DealsModule),
        loadChildren: () => import('./_dealsMVC/deals.module').then(m => m.DealsModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_REPORT_PART,
        // loadChildren: () => import('./_reportsMVC/report.module').then(mod => mod.ReportModule),
        loadChildren: () => import('./_reportsMVC/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_RULES_PART,
        // loadChildren: () => import('./_rulesMVC/rules.module').then(mod => mod.RulesModule),
        loadChildren: () => import('./_rulesMVC/rules.module').then(m => m.RulesModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_PUBLISHER_PART,
        // loadChildren: () => import('./_publisherMVC/publisher.module').then(mod => mod.PublisherModule),
        loadChildren: () => import('./_publisherMVC/publisher.module').then(m => m.PublisherModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_DEMAND_PARTNERS_PART,
        loadChildren: () => import('./_demandPartnersMVC/_myModule/demandPartners.module').then(m => m.DemandPartnersModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_TEST_PART,
        // loadChildren: () => import('./_ztestMVC/ztest.module').then(mod => mod.ZtestModule),
        loadChildren: () => import('./_ztestMVC/ztest.module').then(m => m.ZtestModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_DASHBOARD_CERBERUS_BUYER_PART,
        loadChildren: () => import('./_cerberusMVC/cerberusBuyer/_cerDashboardMVC/cerDashboard.module').then(m => m.CerDashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_MY_PUBLISHERS,
        loadChildren: () => import('./_cerberusMVC/cerberusPublisher/_cerDashboardPublisherMVC/cerDashboardPublisher.module').then(m => m.CerDashboardPublisherModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_DEMAND_MANAGEMENT,
        loadChildren: () => import('./_cerberusMVC/cerberusPublisher/tagConfigurations/_myModule/demandManagement.module').then(m => m.DemandManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_NEW_CAMPAIGN_CERBERUS_BUYER_PART,
        loadChildren: () => import('./_cerberusMVC/cerberusBuyer/_cerCampaignMVC/cerCampaign.module').then(m => m.CerCampaignModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_SELLERS,
        loadChildren: () => import('./_adminMVC/sellers/sellers.module').then(m => m.SellersModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_QUALITY_VETTING_WEB,
        loadChildren: () => import('./_adminMVC/qualityVetting/qualityVetting.module').then(m => m.QualityVettingModule),
        data: {typeParam: 'web'},
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_QUALITY_VETTING_APP,
        loadChildren: () => import('./_adminMVC/qualityVetting/qualityVetting.module').then(m => m.QualityVettingModule),
        data: {typeParam: 'app'},
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_GCMA_AND_IVT,
        loadChildren: () => import('./_adminMVC/gcma-and-ivt/gcma-and-ivt.module').then(m => m.GcmaAndIvtModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_COMMUNICATION_BAR,
        loadChildren: () => import('./_sharedMVC/communicationBarPanel/communicationBarPanel.module').then(m => m.CommunicationBarPanelModule),
        canActivate: [AuthGuard]
      },
      {
        path: Constants.LINK_QUALITY_BLOCKLIST,
        loadChildren: () => import('./_publisherMVC/qualityBlocklist/qualityBlocklist.module').then(m => m.QualityBlocklistModule),
        canActivate: [AuthGuard]
      },
      // otherwise redirect to home
      {path: '**', redirectTo: Constants.LINK_HOME},
    ], {relativeLinkResolution: 'legacy'}),
    GroupSidebarModule,
    SharedPrivateThingsModule,
  ],

  exports: [],

  providers: [
    AuthGuard,
    CanDeactivateGuard,
    JwtPermissions,
    MessageService,
    ConfirmationService,
    SharedThingsModule,
    VersionService,
    PublisherService,
    TargetingService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
